import * as React from 'react';

import { Breadcrumb, BreadcrumbItem, PageSection } from '@patternfly/react-core';
import { Ticketsdata } from './Ticketsdata';

export interface ISupportProps {
  sampleProp?: string;
}

// eslint-disable-next-line prefer-const
let Tickets: React.FunctionComponent<ISupportProps> = () => (
  <>
    <PageSection>
      <Breadcrumb>
        <BreadcrumbItem to="#">Dashboard</BreadcrumbItem>
        <BreadcrumbItem to="#" isActive>
          Tickets
        </BreadcrumbItem>
      </Breadcrumb>
    </PageSection>
    <PageSection>
      <Ticketsdata />
    </PageSection>
  </>
);

export { Tickets };

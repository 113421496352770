/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Button,
  FileUpload,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  TextInput,
  ToggleGroupItemProps,
} from '@patternfly/react-core';
import { Caption, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { ExternalLinkSquareAltIcon, PlusIcon } from '@patternfly/react-icons';

interface Repository {
  name: string;
  branches: string | null;
  prs: string | null;
  workspaces: string;
  lastCommit: string;
}

type ExampleType = 'default' | 'compact' | 'compactBorderless';

export const Ticketsdata: React.FunctionComponent = () => {
  // In real usage, this data would come from some external source like an API via props.
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [filename, setFilename] = React.useState('');

  const handleFileInputChange = (_, file: File) => {
    setFilename(file.name);
  };

  const handleClear = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setFilename('');
    setValue('');
  };
  const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
    setIsModalOpen(!isModalOpen);
  };
  const repositories: Repository[] = [
    {
      name: 'Chat App Help',
      branches: 'App issue',
      prs: 'Application Chat section not working',
      workspaces:
        'We were testing the chat function and several users were unable to access the chat. They got the error "Only team members can chat.  ',
      lastCommit: 'Vivek sharma',
    },
    {
      name: 'Chat App Help',
      branches: 'App issue',
      prs: 'Application Chat section not working',
      workspaces:
        'We were testing the chat function and several users were unable to access the chat. They got the error "Only team members can chat.  ',
      lastCommit: 'Vivek sharma',
    },
    {
      name: 'Chat App Help',
      branches: 'App issue',
      prs: 'Application Chat section not working',
      workspaces:
        'We were testing the chat function and several users were unable to access the chat. They got the error "Only team members can chat.  ',
      lastCommit: 'Vivek sharma',
    },
  ];

  const columnNames = {
    name: 'Ticket type',
    branches: 'Title ',
    prs: 'Subject',
    workspaces: 'Description',
    lastCommit: 'Created by ',
  };

  // This state is just for the ToggleGroup in this example and isn't necessary for Table usage.
  const [exampleChoice, setExampleChoice] = React.useState<ExampleType>('default');
  const onExampleTypeChange: ToggleGroupItemProps['onChange'] = (event, _isSelected) => {
    const id = event.currentTarget.id;
    setExampleChoice(id as ExampleType);
  };
  const options = [
    { value: '', label: '', disabled: false, isPlaceholder: true },
    { value: '1', label: 'Ticket Type', disabled: false, isPlaceholder: false },
    { value: '2', label: 'Ticket Type Two', disabled: false, isPlaceholder: false },
    { value: '3', label: 'Ticket Type Three ', disabled: false, isPlaceholder: false },
  ];
  const priority = [
    { value: '', label: '', disabled: false, isPlaceholder: true },
    { value: '1', label: 'Open', disabled: false, isPlaceholder: false },
    { value: '2', label: 'Solved', disabled: false, isPlaceholder: false },
    { value: '3', label: 'Closed', disabled: false, isPlaceholder: false },
  ];
  return (
    <React.Fragment>
      <Grid hasGutter span={6} style={{ marginBottom: '10px' }}>
        <GridItem></GridItem>
        <GridItem style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="primary" icon={<PlusIcon />} ouiaId="PrimaryWithIcon" onClick={handleModalToggle}>
            Create Ticket
          </Button>
        </GridItem>
      </Grid>
      <Table
        aria-label="Simple table"
        variant={exampleChoice !== 'default' ? 'compact' : undefined}
        borders={exampleChoice !== 'compactBorderless'}
      >
        <Thead>
          <Tr>
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.branches}</Th>
            <Th>{columnNames.prs}</Th>
            <Th>{columnNames.workspaces}</Th>
            <Th>{columnNames.lastCommit}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {repositories.map((repo) => (
            <Tr key={repo.name}>
              <Td dataLabel={columnNames.name}>{repo.name}</Td>
              <Td dataLabel={columnNames.branches}>{repo.branches}</Td>
              <Td dataLabel={columnNames.prs}>{repo.prs}</Td>
              <Td dataLabel={columnNames.workspaces}>{repo.workspaces}</Td>
              <Td dataLabel={columnNames.lastCommit}>{repo.lastCommit}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal
        variant={ModalVariant.medium}
        title="Create Ticket"
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button key="confirm" variant="primary" onClick={handleModalToggle}>
            Submit
          </Button>,
          <Button key="cancel" variant="link" onClick={handleModalToggle}>
            Cancel
          </Button>,
        ]}
      >
        <Form>
          <Grid hasGutter md={6}>
            <GridItem span={6}>
              <FormGroup label="Ticket type" isRequired fieldId="grid-form-email-01">
                <FormSelect id="selection" aria-label="FormSelect Input">
                  {options.map((option, index) => (
                    <FormSelectOption
                      isDisabled={option.disabled}
                      key={index}
                      value={option.value}
                      label={option.label}
                      isPlaceholder={option.isPlaceholder}
                    />
                  ))}
                </FormSelect>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Priority " isRequired fieldId="grid-form-number-01">
                <FormSelect id="selection" aria-label="FormSelect Input">
                  {priority.map((priority, index) => (
                    <FormSelectOption
                      isDisabled={priority.disabled}
                      key={index}
                      value={priority.value}
                      label={priority.label}
                      isPlaceholder={priority.isPlaceholder}
                    />
                  ))}
                </FormSelect>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Subject" isRequired fieldId="grid-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="grid-form-name-01"
                  name="grid-form-name-01"
                  aria-describedby="grid-form-name-01-helper"
                />
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Title" isRequired fieldId="grid-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="grid-form-name-01"
                  name="grid-form-name-01"
                  aria-describedby="grid-form-name-01-helper"
                />
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Description" isRequired fieldId="grid-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="grid-form-name-01"
                  name="grid-form-name-01"
                  aria-describedby="grid-form-name-01-helper"
                />
              </FormGroup>
            </GridItem>
            <GridItem span={8}>
              <FormGroup label="Attachments" isRequired fieldId="grid-form-name-01">
                <FileUpload
                  id="simple-file"
                  value={value}
                  filename={filename}
                  filenamePlaceholder="Drag and drop a file or upload one"
                  onFileInputChange={handleFileInputChange}
                  onClearClick={handleClear}
                  browseButtonText="Upload"
                />
              </FormGroup>
            </GridItem>
            <GridItem span={4}>
              <FormGroup label="Created by" isRequired fieldId="grid-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="grid-form-name-01"
                  name="grid-form-name-01"
                  aria-describedby="grid-form-name-01-helper"
                />
              </FormGroup>
            </GridItem>
          </Grid>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
